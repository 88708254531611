<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('survey')"
                        :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('survey')"
                              :is-filter="false">
                </HeaderMobile>
            </template>
            <h4 v-if="survey">{{ survey.name }}</h4>
            <h5 v-if="survey && survey.course">{{survey.course.code}} - {{survey.course.name}}</h5>
            <hr>
            <div v-if="survey && survey.sections">
                <ValidationObserver ref="formValidate">
                <b-row v-for="section in survey.sections" class="mb-5" v-bind:key="section.id">
                    <div class="col-12">
                        <h5>{{$t('explanation')}}:{{section.explanation}}</h5>
                    </div>
                    <div class="col-12" v-for="question in section.questions" v-bind:key="question.id">
                        <h6>{{question.question}}</h6>
                        <div class="col-12" v-if="question.type==1">
                                <b-form-group v-slot="{ ariaDescribedby }">
                                    <b-form-radio v-model="answers[question.id]" :aria-describedby="ariaDescribedby" :name="'answer'+question.id" :value="option.value"
                                                  v-for="option in question.options" v-bind:key="option.id">{{option.option}}</b-form-radio>
                                </b-form-group>
                                <span class="invalid-feedback-custom" v-show="errors[question.id]" v-html="errors[question.id]"/>
                        </div>
                        <div class="col-12" v-if="question.type==2">
                            <ValidationProvider :name="'answer'+question.id" :rules="question.is_required?'required':''" v-slot="{valid, errors}">
                                <b-form-group>
                                    <b-form-input :id="'answer'+question.id"
                                                  v-model="answers[question.id]"
                                                  :name="'answer'+question.id"
                                    ></b-form-input>
                                </b-form-group>
                                <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                            </ValidationProvider>
                        </div>
                    </div>
                </b-row>
                </ValidationObserver>
                <b-row>
                    <div class="col-12">
                        <processing-button :processing="processing" variant="primary" @click="submit">{{$t('save')}}</processing-button>
                    </div>
                </b-row>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import SurveyService from "@/services/SurveyService";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ProcessingButton from "@/components/elements/ProcessingButton";
import Base from "../../../plugins/Base";

export default {
    components: {
        ProcessingButton,
        AppLayout,
        Header,
        HeaderMobile,
        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.$t("survey"),
        };
    },
    data() {
        return {
            id: this.$route.params.id,
            related_id: this.$route.params.related_id,
            answers: [],
            errors: [],
            survey: null,
            processing: false,
            requiredQuestions:[]
        };
    },
    methods: {
        getSurvey() {
            if (this.id > 0) {
                SurveyService.getForEvaluate(this.id)
                    .then((response) => {
                        this.survey = response.data.data;

                        if(this.related_id){
                            let user = Base.LocalStorage.get('user');
                            for (const item of user.surveys) {
                                if (item.related_id == this.related_id && item.course) {
                                    this.survey.course = item.course;
                                    break;
                                }
                            }
                        }

                        this.survey.sections.forEach(section=>{
                            section.questions.forEach(question=>{
                                if(question.is_required){
                                    this.requiredQuestions.push(question.id);
                                }
                            });
                        })
                    })
                    .catch((error) => {
                        if (error.data.message) {
                            this.$toast.error(this.$t("api." + error.data.message));
                        }
                    });
            }
        },
        getOptions(options){
            return options.map(item=>{
                return {
                    value: item.value,
                    text: item.option
                };
            });
        },
        async submit(){

            var data=[];
            var error=null;
            this.requiredQuestions.forEach(id=>{
                if(!this.answers[id]){
                    error='there_are_missing_responses';
                    this.errors[id]='this_field_is_required';
                    return false;
                }
            });
            this.answers.forEach((value, key)=>{
                data.push({question_id: key, answer: value});
            });
            if(error){
                this.$toast.error(this.$t(error));
                return;
            }
            this.$swal.fire({
                text: this.$t('are_you_sure_want_save'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.processing=true;
                        SurveyService.evaluate(this.id, {answers: data, related_id: this.related_id})
                        .then(response=>{
                            this.requiredQuestions=[];
                            this.answers=[];
                            this.errors=[];

                            this.$refs.formValidate.reset();
                            this.$store.dispatch('auth/getUser').then(()=>{
                                let user = Base.LocalStorage.get('user');
                                let found=false;
                                for (const item of user.surveys) {
                                    if (item.necessity === 1) {
                                        this.id = item.id;
                                        this.related_id = item.related_id ?? 0;
                                        this.$router.push('/surveys/' + item.id + '/evaluate/' + (item.related_id ?? 0));
                                        this.getSurvey();
                                        found=true;
                                        break;
                                    }
                                }
                                if(!found){
                                    this.$router.push('/dashboard');
                                }
                            });
                            this.toast(response.data);
                        }).catch(e=>{
                            this.showErrors(e);
                        })
                        .finally(()=>{
                            this.processing=false;
                        });
                    }
                })
        }
    },
    created() {
        this.getSurvey();
    }
};
</script>

